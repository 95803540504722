import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import moment from "moment";
import customStyle from "../../Admin/customstyle";

const Tournaments = ({ filteredList }) => {
  const { t } = useTranslation();
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL",
  };

  const columns = useMemo(
    () => [
      {
        name: 'Date Time',
        selector: row => (
          <div>
            <div>{moment(row.startday).format('DD.MM.YYYY')}</div>
            <div>{moment(row.startday).format('HH:mm')}</div>
          </div>
        ),
        sortable: true,
        sortFunction: (rowA, rowB) =>
          sortDate(rowA, rowB, "startday"),
      },
      {
        name: 'Tournament Name',
        selector: row => row.title || '',
        sortable: true,
      },
      {
        name: (
          <div>
            <div>Buy-in +</div>
            <div>Bounty</div>
          </div>
        ),
        selector: row => (
          <div className="d-flex flex-column align-items-start">
            <div>{row.buyin || 0} +</div>
            <div>{row.bounty || 0}</div>
          </div>
        ),
      },
      {
        name: <div>
          <div>Re-entry +</div>
          <div>Bounty (R)</div>
        </div>,
        selector: row => (
          <div className="d-flex flex-column align-items-start">
            <div>{row.reentry || 0} +</div>
            <div>{row.reentry_bounty || 0}</div>
          </div>
        ),
      },
      {
        name: 'Registered players',
        selector: row => row.players.registered || 0,
        sortable: true,
      },
      {
        name: 'Players without check-in',
        selector: row => `${row.players.without_checkin} (${row.players.without_checkin_percentage})%`,
        sortable: true,
      },
      {
        name: 'Players with check-in',
        selector: row => `${row.players.checkin} (${row.players.checkin_percentage})%`,
        sortable: true,
      },
      {
        name: 'Re-entries',
        selector: row => `${row.reentries} (${row.reentries_percentage})%`,
        sortable: true,
      },
      {
        name: 'Prize Pool',
        selector: row => row.prize_pool || 0,
        sortable: true,
      },
      {
        name: <div>
          <div>Rake</div>
          <div>Rake (R)</div>
        </div>,
        selector: row => (
          <div className="d-flex flex-column align-items-start">
            <div>{row.rake || 0}</div>
            <div>{row.reentries_rake || 0}</div>
          </div>
        ),
        sortable: true,
      },
      {
        name: 'Total Rakes',
        selector: row => row.total_rakes || 0,
        sortable: true,
      },
    ], [],
  );

  const sortDate = (rowA, rowB) => {
    const a = moment(rowA.startday);
    const b = moment(rowB.startday);

    if (a > b) return 1;
    if (b > a) return -1;
    return 0;
  };

  return (
    <div className="my-3">
      <h3>{t('page.myprofile.myprofilenav.RoomStatistics.Tournaments')}</h3>
      <DataTable
        responsive className="alltournamenttable"
        data={filteredList}
        columns={columns}
        theme="dark"
        selectableRowsComponentProps={{ inkDisabled: true }}
        defaultSortFieldId={1}
        defaultSortAsc={false}
        pagination
        customStyles={customStyle}
        paginationPerPage={100}
        paginationComponentOptions={paginationComponentOptions}
        paginationRowsPerPageOptions={[10, 50, 100]}
      />
    </div>
  );
};

export default Tournaments;