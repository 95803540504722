import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { addDays, subDays } from "date-fns";
import _ from "lodash";

import MultiRangeSlider from "../../../components/MultiRangeSlider/MultiRangeSlider";

const defaultData = {
  start_date: null,
  end_date: null,
  min_buy_in: 0,
  max_buy_in: 300,
};
const minDate = new Date('2024-04-01 00:00:00');
const maxDate = subDays(new Date(), 1);

const Filter = ({ onSearch, onChangeFilter }) => {
  const { t } = useTranslation();
  const [data, setData] = useState(defaultData);

  useEffect(() => {
    onChangeFilter(data);
  }, [data]);

  const handleChangeDate = (name, value) => {
    setData(prev => ({ ...prev, [name]: value }));
  };

  const handleChangeBuyIn = useCallback(
    _.debounce((min, max) => {
      setData(prev => ({ ...prev, min_buy_in: min, max_buy_in: max }));
    }, 100),
    [data],
  );

  const handleResetSearch = () => {
    setData(defaultData);
    onSearch(defaultData);
  };

  return (
    <Row>
      <Col md={3} className="without-date-calander">
        <Form.Group className="mb-1 mb-lg-4 form-group " controlId="">
          <Form.Label>{t('page.myprofile.myprofilenav.RoomStatistics.StartDate')}</Form.Label>
          <DatePicker
            selectsStart
            selected={data.start_date}
            onChange={(date) => handleChangeDate('start_date', date)}
            startDate={data.start_date}
            minDate={minDate}
            maxDate={data.end_date ? subDays(data.end_date, 1) : maxDate}
            dateFormat="dd.MM.yyyy"
            calendarStartDay={1}
          />
        </Form.Group>
      </Col>
      <Col md={3} className="without-date-calander">
        <Form.Group className="mb-1 mb-lg-4 form-group">
          <Form.Label>{t('page.myprofile.myprofilenav.RoomStatistics.EndDate')}</Form.Label>
          <DatePicker
            selectsEnd
            selected={data.end_date}
            onChange={date => handleChangeDate('end_date', date)}
            endDate={data.end_date}
            minDate={data.start_date ? addDays(data.start_date, 1) : minDate}
            maxDate={maxDate}
            dateFormat="dd.MM.yyyy"
            calendarStartDay={1}
          />
        </Form.Group>
      </Col>
      <Col md={3}>
        <Form.Group className="mb-1 mb-lg-4 form-group">
          <Form.Label>{t('page.myprofile.myprofilenav.RoomStatistics.Buy-In')}</Form.Label>
          <MultiRangeSlider
            min={0}
            max={300}
            defaultMinValue={data.min_buy_in}
            defaultMaxValue={data.max_buy_in}
            onChange={({ min, max }) => handleChangeBuyIn(min, max)}
          />
        </Form.Group>
      </Col>
      <Col md={3}>
        <Form.Group className="mb-1 mb-lg-4 form-group">
          <div className="d-flex flex-column align-items-center gap-3">
            <Button varient="parimary" onClick={() => onSearch(data)}>
              {t('page.myprofile.myprofilenav.RoomStatistics.Search')}
            </Button>
            <Button variant="success" onClick={handleResetSearch}>
              {t("Show all")}
            </Button>
          </div>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default Filter;