import React, { useEffect, useState } from "react";
import User from "../../api/services/User";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./index.scss";
import moment from "moment";

const CookieConsent = ({ isLogin, forceOpen, onClose }) => {
  const cookieName = 'CookieConsent';
  const { i18n, t } = useTranslation();
  const [modalShown, setModalShown] = useState(false);
  const [content, setContent] = useState({
    en: '',
    fr: '',
    de: '',
  });
  const [loadedContent, setLoadedContent] = useState(false);

  useEffect(() => {
    if (!loadedContent) return;

    if (forceOpen) {
      setModalShown(true);
      return;
    }

    // Don't check cookie value in login page due to check it in common container.
    if(isLogin) return;

    let cookieVal = localStorage.getItem(cookieName);

    if (!cookieVal || cookieVal === 'accept' || cookieVal === 'reject') {
      setModalShown(true);
      return;
    }

    cookieVal = JSON.parse(cookieVal)
    if (moment().isSameOrAfter(moment(cookieVal?.expire))) {
      setModalShown(true);
    }
  }, [forceOpen, loadedContent]);

  useEffect(() => {
    getContent();
  }, []);

  const getContent = async () => {
    const pageResponse = await User.info().json();

    // TODO: Create a new endpoint for user to get pageSettings by key - page name
    const pageSetting = pageResponse.data.find((item) => item.key === "cookie-consent");
    setContent({ en: "", fr: "", de: "", ...pageSetting?.content });
    setLoadedContent(true);
  };

  const handleCookie = (cookieValue) => {
    localStorage.setItem(cookieName, JSON.stringify({
      value: cookieValue,
      expire: moment().add(6, 'month').format('YYYY-MM-DD'),
    }));
    setModalShown(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal show={modalShown} centered className="cookie-modal">
      <Modal.Header>
        <Modal.Title>{t('page.cookie.This website uses cookies')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div dangerouslySetInnerHTML={{ __html: content[i18n.resolvedLanguage || "en"] }}></div>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="primary" onClick={() => handleCookie('accept')}>{t('page.cookie.Accept')}</Button>
        <Button variant="secondary" onClick={() => handleCookie('reject')}>{t('page.cookie.Reject')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CookieConsent;