import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import TournamentService from "../../../../../api/services/TournamentService";
import "./index.scss";

const PublishModal = ({ tournament, show, onHide }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [disableRegistration, setDisableRegistration] = useState(false);

  useEffect(() => {
    if (!show) return;

    setDisableRegistration(false);
  }, [show]);

  const handleSubmit = async () => {
    if (isLoading) {
      return;
    }

    try {
      setIsLoading(true);
      const userData = {
        id: tournament?.id,
        status: 1,
        disable_registration: disableRegistration,
      };

      const responseData = await TournamentService.updateTournamentStatus(userData).json();
      setIsLoading(false);

      if (responseData.status) {
        onHide(true);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="md" className="publish-modal">
      <Modal.Header>
        <Modal.Title>Publish {tournament?.title}</Modal.Title>
        <button
          className="btn-close"
          aria-label="Close"
          onClick={onHide}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <Form.Check
          type="switch"
          id="disable-registration"
          label="No registration"
          checked={disableRegistration}
          onChange={e => setDisableRegistration(e.target.checked)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit} disabled={isLoading}>
          Publish
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PublishModal;
