
// const customstyle = () => {
  const customStyles = {
       
        rows: {
          style: {
            backgroundColor:'#1A1A1A',
            whiltespace:'pre-line',
          }
        },
        headCells: {
          style: {
            justifyContent:'center',
            backgroundColor:'#1A1A1A',
            whiltespace:'pre-line',
             
          },
          headerProps: {
            className: "lnndaO"
          },
        },
        cells: {
          style: {
           justifyContent:'center',
           textAlign:'center',
          //  '&:hover': {
          //   backgroundColor:'#D3D3D3',
          //   cursor: 'pointer',
          // },
         
           
           },
        },
        navcells:{
          style:{
            justifyContent:'center'
          }
        },

     
      }
      
    
    export default customStyles;