import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, Col, Row } from "react-bootstrap";
import User from "../../../api/services/User";
import RoomService from "../../../api/services/RoomService";
import { Link } from "react-router-dom";

const RMIntegrationWebsite = () => {
  const { t, i18n } = useTranslation();
  const [content, setContent] = useState({ en: "", fr: "", de: "" });
  const [embedCode, setEmbedCode] = useState('');

  useEffect(() => {
    getIntegrationContent();
    getRoom();
  }, []);

  const getIntegrationContent = async () => {
    const pageResponse = await User.info().json();

    // TODO: Create a new endpoint for user to get pageSettings by key - page name
    const pageSetting = pageResponse.data.find(
      (item) => item.key === "rm-website-integration",
    );
    setContent({ en: "", fr: "", de: "", ...pageSetting?.content });
  };

  const getRoom = async () => {
    const responseData = await RoomService.index().json();
    const roomId = responseData.data[0].id ? responseData.data[0].id : "";
    setEmbedCode(`<iframe src="${process.env.REACT_APP_URL}check-raise-tournaments/${roomId}" frameborder="0" style="width: 100%; min-height: 800px;"></iframe>`);
  };

  return (
    <div className="wrapper my-profile-wrapper">
      <Row className="my-5">
        <Col md={10} lg={12}>
          <Card>
            <Card.Header>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
                fill="#fff"
                className="me-1"
              >
                <path
                  d="M448 64c0-17.7-14.3-32-32-32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32zm0 256c0-17.7-14.3-32-32-32H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H416c17.7 0 32-14.3 32-32zM0 192c0 17.7 14.3 32 32 32H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H32c-17.7 0-32 14.3-32 32zM448 448c0-17.7-14.3-32-32-32H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H416c17.7 0 32-14.3 32-32z"/>
              </svg>
              {t("menu.Integration in your website")}
            </Card.Header>
            <Card.Body>
              <div
                className="tiny-content"
                dangerouslySetInnerHTML={{
                  __html: content[i18n.resolvedLanguage || "en"],
                }}
              />

              <h5>Embed code </h5>
              <Link to="#" onClick={() => navigator.clipboard.writeText(embedCode)}>
                <code>{embedCode}</code>
                <small className="text-muted d-block"> (Click to copy to clipboard)</small>
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default RMIntegrationWebsite;
