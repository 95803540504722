import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Table } from "react-bootstrap";
import moment from "moment/moment";
import arrowIcon from "../../assets/images/right-arrow-svgrepo-com.svg";
import logo from "../../assets/images/logo.svg";
import LogoAnimationLoader from "../../components/Loading/LogoAnimationLoader";
import User from "../../api/services/User";

const CheckRaiseTournaments = () => {
  const params = useParams();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState([]);

  useLayoutEffect(() => {
    document.body.style.backgroundColor = "transparent";
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const responseData = await User.filter({ roomIds: [params.roomId] }).json();
      setData(responseData.data || []);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const getProgress = (registeredPlayers, totalPlayers) => {
    const playersProgress = registeredPlayers / totalPlayers;
    let className;

    if (playersProgress <= 0.6) {
      className = "success"; //green
    } else if (playersProgress < 1) {
      className = "warning"; //Orange
    } else {
      className = "error"; // Red
    }
    return "text-start players-progress players-progress--" + className;
  };

  const getProgressWidth = (registeredPlayers, totalPlayers) => {
    return (registeredPlayers * 100) / totalPlayers + "%";
  };

  return (
    <div className="p-3 bg-black">
      <div className="table-container dark-theme d-none d-md-block">
        <Table responsive>
          <thead>
          <tr>
            <th className="width-auto text-center" style={{ width: "140px" }}>
              {t("page.tournaments.tournamentstable.Date")}
            </th>
            <th className="width-auto text-nowrap">
              {t("page.tournaments.tournamentstable.Tournament Name")}
            </th>
            <th className="width-auto text-center d-md-none d-lg-table-cell">
              {t("page.tournaments.tournamentstable.Canton")}
            </th>
            <th className="width-auto text-nowrap">
              {t("page.tournaments.tournamentstable.Type")}
            </th>
            <th className="width-auto text-nowrap">
              {t("page.tournaments.tournamentstable.Buy-in")}
            </th>
            <th className="width-auto text-nowrap">
              {t("page.tournaments.tournamentstable.Players")}
            </th>
            <th className="width-auto text-nowrap" colSpan={2}>
              {t("page.tournamentsdetails.Registration")}
            </th>
          </tr>
          </thead>

          {isLoading
            ? ''
            : <tbody>
            {data.map((element, index) => (
              <tr key={index}>
                <td className="align-middle">
                  <p className="tournament-date text-nowrap">
                    {t("currentdate", { date: new Date(element.detail.startday) })}
                    {moment(element.detail.startday).format(" DD.MM.YYYY HH:mm")}
                  </p>

                  {element.detail.lastday ? (
                    <p className="tournament-date text-nowrap">
                      {moment(element.detail.startday).isSame(moment(element.detail.lastday), 'day') ? (
                        <></>
                      ) : (
                        <>
                          <img
                            src={arrowIcon}
                            width="40"
                            height="50"
                            alt=""
                            style={{ maxHeight: "18px" }}
                          />
                          {moment(element.detail.lastday).format("DD.MM.YYYY")}
                        </>
                      )}
                    </p>
                  ) : (
                    ""
                  )}
                  <p className="tournament-late-reg text-nowrap">
                    {element.detail.lateregformat === "time" ? (
                      <>
                        Late Reg:{" "}
                        {element.detail.lateregtime
                          ? element.detail.lateregtime.slice(0, 5)
                          : ""}
                      </>
                    ) : element.detail.lateregformat === "round" ? (
                      <> Late Reg: Round {element.detail.latereground}</>
                    ) : (
                      ""
                    )}
                  </p>
                </td>
                <td className="align-middle">
                  {element.title}
                </td>
                <td className="text-center align-middle d-md-none d-lg-table-cell">
                  <span>
                    {element.room ? element.room.detail.canton : ""}
                  </span>
                </td>
                <td className="align-middle">
                  <div className="d-flex justify-content-center definition-of-games-flex">
                    {element.detail.maxreentries !== 0 ? (
                      <span className="re-entry defination-games-info-span">R</span>
                    ) : ("")}
                    {element.detail.bounty !== 0 ? (
                      <span className="bounty  defination-games-info-span">B</span>
                    ) : ("")}
                    {element.detail.ischampionship !== 0 ? (
                      <span className=" championship-bagde me-2 v-middle"></span>
                    ) : ("")}
                  </div>
                </td>
                <td className="text-center align-middle">
                  {element.detail.buyin}
                  {element.detail.bounty !== 0
                    ? "+" + element.detail.bounty
                    : ""}
                  {element.detail.rake !== 0
                    ? "+" + element.detail.rake
                    : ""}
                </td>
                <td className="align-middle" style={{ width: "90px" }}>
                  <span
                    className={getProgress(
                      element.players.registered +
                      element.detail.reservedplayers,
                      element.detail.maxplayers,
                    )}
                  >
                    <span
                      className="progress-inner d-inline-block"
                      style={{
                        width: getProgressWidth(
                          element.players.registered +
                          element.detail.reservedplayers,
                          element.detail.maxplayers,
                        ),
                      }}
                    >
                      <span className="text-center w-100 players-position">
                        <span className="players-current">
                          {element.players.registered + element.detail.reservedplayers}
                        </span>
                        {" "}/{" "}
                        <span className="players-total">
                          {element.detail.maxplayers}
                        </span>
                      </span>
                    </span>
                  </span>
                  <span style={{ fontSize: 10 }}>
                    {element.players && element.players.waiting !== 0
                      ? " Waiting list: " + element.players.waiting
                      : ""}
                  </span>
                </td>
                <td className="align-middle">
                  <Link
                    className="btn btn-sm btn-primary btn-register-tournament"
                    to={`/tournaments/${element.room.slug}/${element.slug}`}
                    target="_blank"
                  >
                    Info
                  </Link>
                </td>
              </tr>
            ))}
            </tbody>
          }
        </Table>
      </div>

      <div className="table-tournaments-mobile d-block d-md-none dark-theme">
        {data.map((element, index) => (
          <div key={index} className="tournament-row">
            <div className="d-flex justify-content-between">
              <div className="tm-title text-truncate">
                {element.title}
              </div>
              <div className="tm-players d-flex align-items-center flex-wrap justify-content-end">
                <span
                  className={getProgress(
                    element.players.registered +
                    element.detail.reservedplayers,
                    element.detail.maxplayers,
                  )}
                >
                  <span
                    className="progress-inner d-inline-block"
                    style={{
                      width: getProgressWidth(
                        element.players.registered +
                        element.detail.reservedplayers,
                        element.detail.maxplayers,
                      ),
                    }}
                  >
                    <span className="text-center w-100 players-position">
                      <span className="players-current">
                        {element.players.registered +
                          element.detail.reservedplayers}{" "}
                      </span>
                      /
                      <span className="players-total">
                        {" "}
                        {element.detail.maxplayers}
                      </span>
                    </span>
                  </span>
                </span>
                <span
                  className="w-100 text-end"
                  style={{ fontSize: 10, marginTop: 2, marginBottom: -12 }}
                >
                  {element.players && element.players.waiting !== 0
                    ? "(Waiting list: " + element.players.waiting + ")"
                    : ""}
                    </span>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="tm-middle">
                <div className="tm-date">
                  {t("currentdate", { date: new Date(element.detail.startday) })}
                  {moment(element.detail.startday).format(" DD.MM.YYYY")}
                </div>
                {element.detail.lastday ? (
                  <p className="tm-date text-nowrap mb-0">
                    {moment(element.detail.startday).isSame(moment(element.detail.lastday), 'day') ? (
                      <></>
                    ) : (
                      <>
                        <img
                          src={logo}
                          width="20"
                          height="20"
                          alt=""
                          style={{ maxHeight: "18px" }}
                        />
                        {moment(element.detail.lastday).format(
                          "DD.MM.YYYY",
                        )}
                      </>
                    )}
                  </p>
                ) : (
                  ""
                )}
                <div className="tm-time">
                  {moment(element.detail.startday).format("HH:mm")}
                </div>
                <p className="tm-late-reg text-nowrap">
                  {element.detail.lateregformat === "time" ? (
                    <>
                      Late Reg:{" "}
                      {element.detail.lateregtime
                        ? element.detail.lateregtime.slice(0, 5)
                        : ""}
                    </>
                  ) : element.detail.lateregformat === "round" ? (
                    <> Late Reg: Round {element.detail.latereground}</>
                  ) : (
                    ""
                  )}
                </p>
              </div>

              <div className="d-flex justify-content-between flex-column">
                <Link
                  className="btn btn-sm btn-primary btn-register-tournament"
                  to={`/tournaments/${element.room.slug}/${element.slug}`}
                  target="_blank"
                >
                  Info
                </Link>
              </div>
            </div>

            <div className="d-flex justify-content-end mt-2">
              <span className="tm-type type-column d-block text-end">
                <span className="tm-type d-block">
                  Buy-in: {element.detail.buyin}
                  {element.detail.rake !== 0 ? "+" + element.detail.rake : ""}
                  {element.detail.bounty !== 0 ? "+" + element.detail.bounty : ""}
                </span>
                {element.detail.maxreentries !== 0 ? (
                  <span className="re-entry defination-games-info-span">R</span>
                ) : (
                  ""
                )}
                {element.detail.bounty !== 0 ? (
                  <span className="bounty  defination-games-info-span">B</span>
                ) : (
                  ""
                )}
                {element.detail.ischampionship !== 0 ? (
                  <span className=" championship-bagde me-2 v-middle"></span>
                ) : (
                  ""
                )}
              </span>
            </div>
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column align-items-start">
          <h5>Powered by</h5>
          <Link to="/" target="_blank">
            <img src={logo} width="70" alt="Check-Raise"/>
          </Link>
        </div>
        <div className="defination-games-info">
          <ul className="p-0 list-unstyled d-flex">
            <li>
              <span className="re-entry">R</span>Re-entry
            </li>
            <li>
              <span className="bounty">B</span>Bounty
            </li>
            <li>
              <span className="championship">Championship</span>
            </li>
          </ul>
        </div>
      </div>

      {isLoading && <LogoAnimationLoader/>}
    </div>
  );
};

export default CheckRaiseTournaments;
