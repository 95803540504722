import React from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { downloadExcel } from "react-export-table-to-excel";
import moment from "moment";
import { formatPhoneNumber } from "../../../../utils";

const PlayerInfoModal = ({ show, onHide, playerInfo, isMembership }) => {
  const handleSaveExcel = async () => {
    const header = ['Player Information'];

    const body = [
      ['Name', playerInfo?.firstname],
      ['Surname', playerInfo?.lastname],
      ['Nickname', playerInfo?.nickname],
      ['Street and number', playerInfo?.street],
      ['ZIP and City', `${playerInfo?.zipcode} ${playerInfo?.city}`],
      [
        'Birthdate',
        playerInfo?.dob
          ? moment(playerInfo?.dob).format("DD.MM.YYYY")
          : "",
      ],
      ['E-Mail Address', playerInfo?.email],
      ['Phone Number', formatPhoneNumber(playerInfo?.phonecode, playerInfo?.phonenumber)],
      [
        'First Registration',
        playerInfo?.firstRegistrationDate
          ? moment(playerInfo?.firstRegistrationDate).format("DD.MM.YYYY")
          : "Before 04.2024",
      ],
      [
        'Membership',
        !isMembership
          ? "Not active"
          : playerInfo?.membership && playerInfo?.membership !== "-"
            ? moment(playerInfo?.membership).format("DD.MM.YYYY")
            : "-",
      ],
    ];

    downloadExcel({
      fileName: "Check-Raise",
      sheet: "PlayerInfo",
      tablePayload: {
        header,
        body,
      },
    });
  };

  return (
    <>
      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>
            {playerInfo?.firstname} {playerInfo?.lastname}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Form.Label>Name : {playerInfo?.firstname} </Form.Label>
            <Form.Label>Surname : {playerInfo?.lastname}</Form.Label>
            <Form.Label>Nickname : {playerInfo?.nickname}</Form.Label>
            <Form.Label>Street and number : {playerInfo?.street}</Form.Label>
            <Form.Label>
              ZIP and City : {playerInfo?.zipcode} {playerInfo?.city}
            </Form.Label>
            <Form.Label>
              Birthdate :{" "}
              {playerInfo?.dob
                ? moment(playerInfo?.dob).format("DD.MM.YYYY")
                : ""}
            </Form.Label>
            <Form.Label>
              E-Mail Address :{" "}
              <a className="text-white" href={`mailto:${playerInfo?.email}`}>
                {playerInfo?.email}
              </a>
            </Form.Label>
            <Form.Label>
              Phone Number :{" "}
              {formatPhoneNumber(playerInfo?.phonecode, playerInfo?.phonenumber)}
            </Form.Label>
            <Form.Label>
              First Registration:{" "}
              {playerInfo?.firstRegistrationDate
                ? moment(playerInfo?.firstRegistrationDate).format("DD.MM.YYYY")
                : "Before 04.2024"}
            </Form.Label>
            <Form.Label>
              Membership:{" "}
              {!isMembership
                ? "Not active"
                : playerInfo?.membership && playerInfo?.membership !== "-"
                  ? moment(playerInfo?.membership).format("DD.MM.YYYY")
                  : "-"}
            </Form.Label>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
          <Button variant="primary" download onClick={handleSaveExcel}>
            Save Excel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PlayerInfoModal;
