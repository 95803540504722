import React from "react";
import { Col, Row } from "react-bootstrap";

const RoomStatistics = ({ roomStatistics }) => {

  return (
    <Row>
      <Col md={12}>
        <h3>Room Statistics on finished tournaments (without reservations):</h3>
      </Col>
      <Col md={12}>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            Number of tournaments: {roomStatistics.tournaments || 0}
          </li>
          <li className="list-group-item">
            Registered players: {roomStatistics.registered_players || 0}
          </li>
          <li className="list-group-item">
            Players without check-in: {roomStatistics.players_without_check_in || 0}
            <span className="ms-4">({roomStatistics.players_without_check_in_percentage})</span>
          </li>
          <li className="list-group-item">
            Players with check-in: {roomStatistics.players_with_check_in || 0}
            <span className="ms-4">({roomStatistics.players_with_check_in_percentage})</span>
          </li>
          <li className="list-group-item">
            Number of re-entries (with check-in only): {roomStatistics.re_entries || 0}
            <span className="ms-4">({roomStatistics.re_entries_percentage})</span>
          </li>
          <li className="list-group-item">
            Cumulated prize pools, including Bounties (with check-in only): {roomStatistics.cumulated_prize_pools || 0}
          </li>
          <li className="list-group-item">
            Cumulated Rakes (with check-in only): {roomStatistics.cumulated_rakes || 0}
          </li>
        </ul>
      </Col>
    </Row>
  );
};
export default RoomStatistics;