import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from "moment";
import * as XLSX from "xlsx";

import RoomService from '../../../api/services/RoomService';
import LogoAnimationLoader from '../../../components/Loading/LogoAnimationLoader';
import Filter from "./Filter";
import RoomStatistics from "./RoomStatistics";
import Tournaments from "./Tournaments";

const RoomsStatistics = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState([]);
  const [roomTitle, setRoomTitle] = useState('');
  const [roomStatistics, setRoomStatistics] = useState({});
  const [tournaments, setTournaments] = useState([]);
  const [filteredList, setFilteredList] = new useState([]);

  useEffect(() => {
    if (localStorage.getItem('usertoken')) {
      getTournaments();
    } else {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    getRoomStatistics();
  }, [filteredList]);

  const getTournaments = async () => {
    const responseData = await RoomService.tournamentList().json();
    setIsLoading(false);
    setTournaments(responseData.data);
    setFilteredList(responseData.data);
    setRoomTitle(responseData.roomTitle);
  };

  const handleSearch = async (filterData) => {
    try {
      let data = [].concat(tournaments);

      if (filterData.min_buy_in > 0 || filterData.max_buy_in < 300) {
        data = data.filter(row => (
          row.buyin >= filterData.min_buy_in && row.buyin <= filterData.max_buy_in
        ));
      }

      if (!filterData.start_date && !filterData.end_date) {
        return setFilteredList(data);
      }

      const startDate = filterData.start_date ? moment(filterData.start_date) : moment('2024-04-01 00:00:00');
      const endDate = filterData.end_date ? moment(filterData.end_date) : moment().subtract(1, 'day');
      startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      endDate.set({ hour: 23, minute: 59, second: 0, millisecond: 0 });

      data = data.filter(row => (
        moment(row.startday).isBetween(startDate, endDate, undefined, '[]')
      ));
      setFilteredList(data);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  const getRoomStatistics = () => {
    const data = {
      tournaments: filteredList.length,
      registered_players: 0,
      players_without_check_in: 0,
      players_without_check_in_percentage: 0,
      players_with_check_in: 0,
      players_with_check_in_percentage: 0,
      re_entries: 0,
      re_entries_percentage: 0,
      cumulated_prize_pools: 0,
      cumulated_rakes: 0,
    };

    filteredList.map(item => {
      data.registered_players += item.players.registered;
      data.players_without_check_in += item.players.without_checkin;
      data.players_with_check_in += item.players.checkin;
      data.re_entries += item.reentries;
      data.cumulated_prize_pools += item.prize_pool;
      data.cumulated_rakes += item.total_rakes;
    });

    data.players_without_check_in_percentage = `${getPercentage(data.registered_players, data.players_without_check_in)}%`;
    data.players_with_check_in_percentage = `${getPercentage(data.registered_players, data.players_with_check_in)}%`;
    data.re_entries_percentage = `${getPercentage(data.players_with_check_in, data.re_entries)}%`;
    setRoomStatistics(data);
  };

  function handleDownloadExcel() {
    const startDate = filters.start_date ? moment(filters.start_date) : moment('2024-04-01');
    const endDate = filters.end_date ? moment(filters.end_date) : moment().subtract(1, 'day');
    const headerRows = [
      ['Room', roomTitle],
      ['Start Date', startDate.format('DD.MM.YYYY')],
      ['End Date', endDate.format('DD.MM.YYYY')],
      ['Buy-in', `${filters.min_buy_in} - ${filters.max_buy_in}`],
      [''],
    ];

    const roomStatisticsRows = [
      ['Number of tournaments', roomStatistics.tournaments],
      ['Registered players', roomStatistics.registered_players],
      [
        'Players without check-in',
        roomStatistics.players_without_check_in,
        roomStatistics.players_without_check_in_percentage,
      ],
      [
        'Players with check-in',
        roomStatistics.players_with_check_in,
        roomStatistics.players_with_check_in_percentage,
      ],
      [
        'Number of re-entries (with check-in only)',
        roomStatistics.re_entries,
        roomStatistics.re_entries_percentage,
      ],
      ['Cumulated Prize pools (with check-in only)', roomStatistics.cumulated_prize_pools],
      ['Cumulated Rakes (with check-in only)', roomStatistics.cumulated_rakes],
      [''],
      [''],
    ];

    const tournamentsRows = [
      [
        'Date / Time',
        'Tournament name',
        'Buy-in',
        'Bounty',
        'Re-entry',
        'Bounty by Re-entry',
        'Registered players',
        'Players without check-in',
        'Players without check-in [%]',
        'Players with check-in',
        'Players with check-in [%]',
        'Re-entries',
        'Re-entries [%]',
        'Prize Pool',
        'Rake',
        'Rake of re-entries',
        'Total Rakes',
      ],
    ];

    filteredList.map(item => {
      const row = [
        moment(item.startday).format('DD.MM.YYYY HH:mm'),
        item.title,
        item.buyin,
        item.bounty,
        item.reentry,
        item.reentry_bounty,
        item.players.registered,
        item.players.without_checkin,
        `${item.players.without_checkin_percentage}%`,
        item.players.checkin,
        `${item.players.checkin_percentage}%`,
        item.reentries,
        `${item.reentries_percentage}%`,
        item.prize_pool,
        item.rake,
        item.reentries_rake,
        item.total_rakes,
      ];
      tournamentsRows.push(row);
    });

    const body = [].concat(headerRows, roomStatisticsRows, tournamentsRows);

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(
      body,
      { skipHeader: true },
    );

    XLSX.utils.book_append_sheet(wb, ws, "Room Statistics");
    console.log(headerRows);
    XLSX.writeFile(wb, `Statistics ${roomTitle} ${headerRows[1][1]} - ${headerRows[2][1]} .xlsx`);
  }

  const getPercentage = (total, numerator) => {
    let percentage = 0;

    if (total > 0 && numerator > 0) {
      percentage = ((numerator / total) * 100).toFixed(2);
    }

    return percentage;
  };

  return (
    <>
      <div className="wrapper my-profile-wrapper">
        <Row className="my-5">
          <Col md={10} lg={12}>
            <Card>
              <Card.Header>
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" fill="#fff" className="me-1">
                  <path
                    d="M448 64c0-17.7-14.3-32-32-32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32zm0 256c0-17.7-14.3-32-32-32H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H416c17.7 0 32-14.3 32-32zM0 192c0 17.7 14.3 32 32 32H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H32c-17.7 0-32 14.3-32 32zM448 448c0-17.7-14.3-32-32-32H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H416c17.7 0 32-14.3 32-32z"/>
                </svg>
                {t('page.myprofile.myprofilenav.RoomStatistics.Statistics')}
              </Card.Header>
              <Card.Body>
                <Filter onSearch={handleSearch} onChangeFilter={setFilters}/>

                <RoomStatistics roomStatistics={roomStatistics}/>

                <Tournaments filteredList={filteredList}/>

                <div className="mt-3 text-end">
                  <Button onClick={handleDownloadExcel}>Export Excel</Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>

      {isLoading && <LogoAnimationLoader/>}
    </>
  );
};

export default RoomsStatistics;