import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import InputPhoneComponent from '../../../components/InputPhone/InputPhone';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import './MyProfile.scss';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import MyProfileService from '../../../api/services/MyProfileService';
import LogoAnimationLoader from '../../../components/Loading/LogoAnimationLoader';
import RoomService from '../../../api/services/RoomService';
import Modal from 'react-bootstrap/Modal';
import { getClearPhoneNumber } from "../../../utils";

const eye = <FontAwesomeIcon icon={faEye}/>;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash}/>;
const options = [
  { value: 'fr', label: 'Français' },
  { value: 'en', label: 'English' },
  { value: 'de', label: 'Deutsch' },
];

const MyProfile = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [errors, setErrors] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [modalShown, setModalShown] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [phonecountry, setPhonecountry] = useState("");
  const [phonecode, setPhonecode] = useState("");
  const [phonenumber, setPhoneNumber] = useState("");
  const [phonedisplay, setPhoneDisplay] = useState("");
  const [userData, setUserData] = useState([]);
  const [pokerCity, setPokerCity] = useState("");

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  const getProfileList = async () => {
    try {
      const responseData = await MyProfileService.Show().json();
      setUserData(responseData.data);
      let selectedlanguage = options.filter(function (item) {
        return item.value === responseData.data.language;
      });
      setSelectedLanguage(selectedlanguage);

      const clearPhoneNumber = getClearPhoneNumber(responseData.data.phonenumber || '');
      setPokerCity(responseData.data.city);
      setPhoneDisplay(responseData.data.phonecode + clearPhoneNumber);
      setPhoneNumber(clearPhoneNumber);


      setIsLoading(false);

    } catch (error) {
      console.log(error);
    }
  };
  const handleZipcodeChange = async (event, type) => {
    event.preventDefault();
    var code = event.target.value;
    try {
      const res = await RoomService.getCity(code).json();
      if (type === "poker") {
        setPokerCity(res.data.city);
      }
    } catch (error) {
      if (type === "poker") {
        setPokerCity('');
      }

    }
  };
  useEffect(() => {

    if (localStorage.getItem('usertoken')) {
      getProfileList();
    } else {
      navigate('/');
    }

  }, []);

  const handleOnChange = (value, data) => {
    if (!value) {
      setPhonecountry("");
      setPhonecode("");
      setPhoneNumber("");
      return;
    }
    setPhonecountry(data?.countryCode || '');
    setPhonecode(data?.dialCode || '');
    setPhoneNumber(value.slice(data?.dialCode?.length));

  };

  const handleLanguage = selectedLanguange => {
    setSelectedLanguage(selectedLanguange);

  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (event.target.password.value !== event.target.confirmpassword.value) {
      setErrors("Password and Confirm password not same..");
    } else {
      try {
        const data = await MyProfileService.update(
          {
            email: event.target.email.value,
            firstname: event.target.firstname.value,
            lastname: event.target.lastname.value,
            dob: event.target.dob.value,
            street: event.target.street.value,
            language: selectedLanguage.value ? selectedLanguage.value : userData.language,
            city: pokerCity || event.target.city.value,
            zipcode: event.target.zipcode.value,
            phonecountry: phonecountry ? phonecountry : userData.phonecountry,
            phonecode: phonecode ? phonecode : userData.phonecode,
            phonenumber: phonenumber ? phonenumber : userData.phonenumber,
            newpassword: event.target.password.value,
          },
        ).json();

        if (data.status === true) {
          setModalShown(true);
          setModalMessage(data.message);
          setErrors('');
        } else {
          setErrors("Please Fill All Fields* ");
        }
      } catch (error) {
        if (error.name === 'HTTPError') {
          const errorJson = await error.response.json();

          setErrors(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")));
        }
      }
    }
  };

  return (
    <>
      <div className="wrapper my-profile-wrapper">
        <Row className="d-none d-md-flex">
          <Col className="text-center">
            <h1 className="d-block">{t('page.changeprofile')}</h1>
          </Col>
        </Row>
        <Row>
          <Col md={10} lg={12}>
            <Row className="d-flex d-md-none">
              <Col className="text-center">
                <h1 className="d-block">{t('page.changeprofile')}</h1>
              </Col>
            </Row>
            <div className="login-formwrap register-formwrap my-profile-formwrap">
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3 form-group" controlId="firstname">
                      <Form.Label>{t('page.registration.Name')}*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t('page.registration.Name')}
                        name="firstname"
                        defaultValue={userData.firstname}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3 form-group email-group" controlId="email">
                      <Form.Label>{t('page.registration.E-Mail')}*
                        <OverlayTrigger
                          key="top"
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-top">
                              {t('page.registration.Bychangingemailaddress')}
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon icon={faQuestionCircle}/>
                        </OverlayTrigger>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder={t('page.registration.E-Mail')}
                        name="email"
                        defaultValue={userData.email}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3 form-group" controlId="lastname">
                      <Form.Label>{t('page.registration.Surname')}*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t('page.registration.Surname')}
                        className=""
                        name="lastname"
                        defaultValue={userData.lastname}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3 form-group" controlId="phone">
                      <Form.Label>{t('page.registration.Phone Number')}*</Form.Label>
                      <div className="flag-select">
                        <InputPhoneComponent fn={handleOnChange} phonecode={phonedisplay}/>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={12}>
                        <Form.Group className="mb-3 form-group" controlId="street">
                          <Form.Label>{t('page.registration.Street and number')}*</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={t('page.registration.Street and number')}
                            className=""
                            name="street"
                            defaultValue={userData.street}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3 form-group" controlId="zipcode">
                          <Form.Label>{t('page.registration.ZIP code')}*</Form.Label>
                          <Form.Control
                            onWheel={(e) => e.target.blur()}
                            type="number"
                            placeholder="e.g. 774843"
                            name="zipcode"
                            defaultValue={userData.zipcode}
                            onBlur={(e) => handleZipcodeChange(e, 'poker')}
                            pattern="[0-9]"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3 form-group" controlId="city">
                          <Form.Label>{t('page.registration.City')}*</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="City"
                            name="city"
                            onChange={(e) => setPokerCity(e.target.value)} value={pokerCity}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={12}>
                        <Form.Group className="mb-3 form-group position-relative" controlId="password">
                          <Form.Label>{t('page.registration.Newpassword')}*</Form.Label>
                          <Form.Control
                            type={passwordShown ? "text" : "password"}
                            placeholder={t('page.registration.Newpassword')}
                            name="password"
                            autoComplete="new-password"
                          />
                          <span className="faEye-icon">
                            <i onClick={togglePasswordVisibility}>{passwordShown ? eye : eyeSlash}</i>
                          </span>
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        <Form.Group className="mb-3 form-group position-relative" controlId="confirmpassword">
                          <Form.Label>{t('page.registration.Confirm password')}*</Form.Label>
                          <Form.Control
                            type={confirmPasswordShown ? "text" : "password"}
                            placeholder={t('page.registration.Confirm password')}
                            name="confirmpassword"
                          />
                          <span className="faEye-icon">
                            <i onClick={toggleConfirmPasswordVisibility}>{confirmPasswordShown ? eye : eyeSlash}</i>
                          </span>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3 form-group" controlId="dob">
                      <Form.Label>{t('page.registration.Date of birth')}*</Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="DD.MM.YYYY"
                        name="dob"
                        defaultValue={userData.dob}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3 form-group" controlId="language">
                      <Form.Label>{t('page.registration.Language')}*</Form.Label>
                      <Select
                        options={options}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        value={selectedLanguage}
                        onChange={handleLanguage}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12} className="text-center mt-1">
                    <p className="success" style={{ color: "white", display: "none" }}>
                      {t('page.registration.Success')}
                    </p>
                    {errors
                      ? <p className="error" style={{ color: "red" }}>{errors}</p>
                      : ''
                    }
                    <Button type="submit" className="btn btn-primary btn-submit">
                      {t('page.registration.Save')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </div>

      {isLoading && <LogoAnimationLoader/>}

      <Modal show={modalShown}>
        <Modal.Header>
          <Modal.Title>Saved</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setModalShown(false);
            }}
          >
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MyProfile;