import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, Row, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import User from "../../../api/services/User";
import LogoAnimationLoader from "../../../components/Loading/LogoAnimationLoader";

const EmailVerification = () => {
  const params = useParams();
  const {t} = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState("");
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    handleVerify();
  }, []);

  const handleVerify = async () => {
    const token = params.token || "";
    if (!token) {
      setErrors("Invalid token.");
      setIsLoading(false);
      return;
    }

    try {
      const response = await User.verifyEmail(token).json();

      if (response.status) {
        setErrors("");
        setIsVerified(true);
      } else {
        setErrors(response?.message || "");
      }
    } catch (error) {
      if (error.name === "HTTPError") {
        const errorJson = await error.response.json();
        setErrors(errorJson.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main>
      <div className="wrapper">
        <Row className="justify-content-center">
          <Col md={8}>
            <Card>
              <Card.Body>
                {!isVerified && errors ? <p className="error">{errors}</p> : ""}
                {isVerified && (
                  <div className="text-center">
                    <p>{t('Email Verified')}</p>
                    <Link to="/login">
                      Login
                    </Link>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>

      {isLoading && <LogoAnimationLoader/>}
    </main>
  );
};
export default EmailVerification;
