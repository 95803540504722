import React, { useLayoutEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import "./custom-textarea.scss";

const CustomTextArea = ({ sign, value, onChange }) => {
  const textareaRef = useRef(null)

  useLayoutEffect(() => {
    // Reset height - important to shrink on delete
    textareaRef.current.style.height = "auto";
    // Set height
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  }, [value]);

  const handleFocus = () => {
    textareaRef.current.focus();
  }

  return (
    <div className="custom-textarea" onClick={handleFocus}>
      <Form.Control
        ref={textareaRef}
        as="textarea"
        name="content"
        onChange={onChange}
        value={value}
      />
      <pre>{sign}</pre>
    </div>
  );
};

export default CustomTextArea;