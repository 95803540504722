import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { PAYPAL_CLIENT_ID } from "../../../Config/Config";

import customStyle from "../../Admin/customstyle";
import LogoAnimationLoader from "../../../components/Loading/LogoAnimationLoader";
import User from "../../../api/services/User";
import TournamentService from "../../../api/services/TournamentService";
import SettingService from "../../../api/services/SettingService";

const initialOptions = {
  clientId: PAYPAL_CLIENT_ID,
  currency: "CHF",
  intent: "capture",
};

const Advertising = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [advertisingData, setAdvertisingData] = useState({
    top_banner: {},
    bottom_banner: {},
    premium_tournament: {},
  });
  const [content, setContent] = useState({ en: "", fr: "", de: "" });
  const [enabledPayPal, setEnabledPayPal] = useState(true);
  const [paypalFee, setPaypalFee] = useState(0);
  const [creditData, setCreditData] = useState([]);

  const [showPayPal, setShowPayPal] = useState(false);
  const [amount, setAmount] = useState("");
  const [balance, setBalance] = useState(0);
  const [errors, setErrors] = useState("");

  useEffect(() => {
    if (!localStorage.getItem("usertoken")) {
      return navigate("/");
    }

    Promise.all([
      getAdvertising(),
      getPageSettingContent(),
      getSettings(),
      getCredits(),
    ]).then(() => setIsLoading(false));
  }, [navigate]);

  const getAdvertising = async () => {
    try {
      const responseData = await TournamentService.getAdvertsing().json();
      const adData = {
        top_banner: {},
        bottom_banner: {},
        premium_tournament: {},
      };
      responseData.data.map(item => adData[item.key] = item);

      setAdvertisingData(adData);
    } catch (error) {
      console.log(error);
    }
  };

  const getPageSettingContent = async () => {
    const pageResponse = await User.info().json();

    // TODO: Create a new endpoint for user to get pageSettings by key - page name
    const pageSetting = pageResponse.data.find(
      (item) => item.key === "rm-advertising",
    );

    if (pageSetting?.content) {
      setContent(pageSetting.content);
    }
  };

  const getSettings = async () => {
    // TODO: Create a new endpoint for user to get setting by key
    const response = await SettingService.siteSettings().json();
    setEnabledPayPal(response.data?.[0]?.is_paypal === 1);
    setPaypalFee(response.data?.[0]?.paypal_fee || 0);
  };

  const getCredits = async () => {
    try {
      const responseData = await TournamentService.getCredits().json();
      setCreditData(responseData.data);
      setBalance(responseData.balance);
    } catch (error) {
      console.log(error);
    }
  };

  const handleBuyCredit = () => {
    amount === "" ? setErrors("Please Enter Amount") : setShowPayPal(true);
  };

  const createOrder = (data, actions) => {
    const fee = Math.ceil((amount * paypalFee) / 100) || 0;

    return actions.order.create({
      purchase_units: [{
        description: "Purchase Credit",
        amount: {
          value: parseInt(amount) + parseFloat(fee),
        },
      }],
    });
  };

  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then((details) => {
      console.log('onApprove => details', details);
      checkOrder(details.id);
    });
  };

  const checkOrder = async (orderId) => {
    try {
      await TournamentService.checkOrder({ order_id: orderId }).json();
      getCredits();
      setAmount('');
    } catch (error) {
      if (error.name === "HTTPError") {
        const errorJson = await error.response.json();
        setErrors(errorJson.message);
      }
    }
  };

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL",
  };

  const columns = useMemo(
    () => [
      {
        name: "Number",
        selector: (row) => (row ? row.id : ""),
        sortable: true,
      },
      {
        name: "Description",
        selector: (row) => (row ? row.description : ""),
        sortable: true,
      },
      {
        name: "Amount",
        selector: (row) => (row ? row.amount : ""),
        sortable: true,
      },
    ],
    [],
  );

  return (
    <>
      <div className="wrapper my-profile-wrapper">
        <Row className="my-5">
          <Col md={10} lg={12}>
            <Card>
              <Card.Header>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 448 512"
                  fill="#fff"
                  className="me-1"
                >
                  <path
                    d="M448 64c0-17.7-14.3-32-32-32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32zm0 256c0-17.7-14.3-32-32-32H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H416c17.7 0 32-14.3 32-32zM0 192c0 17.7 14.3 32 32 32H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H32c-17.7 0-32 14.3-32 32zM448 448c0-17.7-14.3-32-32-32H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H416c17.7 0 32-14.3 32-32z"/>
                </svg>
                {t("page.Manager.TournamentsAdvertising.Label")}
              </Card.Header>
              <Card.Body>
                <Table responsive className="alltournamenttable">
                  <thead>
                  <tr style={{ textAlign: "center" }}>
                    <th></th>
                    <th>{t("page.Manager.TournamentsAdvertising.Credits")}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr style={{ textAlign: "center" }}>
                    <td>{t("page.Manager.TournamentsAdvertising.topbanner")}</td>
                    <td>{advertisingData.top_banner.perday}</td>
                  </tr>
                  <tr style={{ textAlign: "center" }}>
                    <td>{t("page.Manager.TournamentsAdvertising.centerbanner")}</td>
                    <td>{advertisingData.bottom_banner.perday}</td>
                  </tr>
                  <tr style={{ textAlign: "center" }}>
                    <td>{t("page.Manager.TournamentsAdvertising.premiumbanner")}</td>
                    <td>{advertisingData.premium_tournament.perday}</td>
                  </tr>
                  </tbody>
                </Table>
              </Card.Body>
              <Card.Body>
                <div className="tiny-content"
                     dangerouslySetInnerHTML={{
                       __html: content[i18n.resolvedLanguage || "en"],
                     }}
                />
              </Card.Body>
              {enabledPayPal ? (
                <Card.Body>
                  <PayPalScriptProvider options={initialOptions}>
                    <div className="product-info">
                      <div className="product-price-btn">
                        <div className="mb-5">
                          <input onChange={e => setAmount(e.target.value)}/>
                        </div>
                        <button className="buy-btn btn btn-primary" onClick={handleBuyCredit}>
                          Buy Credits
                        </button>

                        <p className="error">{errors}</p>

                        {showPayPal ? (
                          <PayPalButtons
                            style={{ layout: "vertical" }}
                            createOrder={createOrder}
                            onApprove={onApprove}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </PayPalScriptProvider>
                </Card.Body>
              ) : (
                ""
              )}

              <Card.Header>
                {t("page.Manager.TournamentsAdvertising.mycredit")} : {balance}
              </Card.Header>
              <Card.Header>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 448 512"
                  fill="#fff"
                  className="me-1"
                >
                  <path
                    d="M448 64c0-17.7-14.3-32-32-32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32zm0 256c0-17.7-14.3-32-32-32H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H416c17.7 0 32-14.3 32-32zM0 192c0 17.7 14.3 32 32 32H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H32c-17.7 0-32 14.3-32 32zM448 448c0-17.7-14.3-32-32-32H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H416c17.7 0 32-14.3 32-32z"/>
                </svg>
                {t("page.Manager.TournamentsAdvertising.mytransaction")}
              </Card.Header>
              <Card.Body>
                <DataTable
                  data={creditData}
                  columns={columns}
                  theme="dark"
                  selectableRowsComponentProps={{ inkDisabled: true }}
                  defaultSortFieldId={1}
                  defaultSortAsc={false}
                  pagination
                  customStyles={customStyle}
                  paginationPerPage={100}
                  paginationComponentOptions={paginationComponentOptions}
                  paginationRowsPerPageOptions={[10, 50, 100]}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      {isLoading && <LogoAnimationLoader/>}
    </>
  );
};

export default Advertising;
